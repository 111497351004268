import { http } from "../http";
import { facetSerializer } from "../serializers/facet";

export class FacetAPI {

    private static INSTANCE = new FacetAPI();

    static get instance() {
        return this.INSTANCE;
    }

    private namespace = 'api/admin/v1';

    private mcNamespace = 'api/missioncontrol/v1';

    private lookupNamespace = 'api/nucleus/v1/lookups';

    private lrNamespace = 'api/learning-registry/v1';

    private deeplinkNamespace = '/api/nucleus-lti/v1/lti/v1p3';

    public fetchFacetsList() {
        const endpoint = `${this.namespace}/settings/facets`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers).then((response) => {
            return facetSerializer.normalizeFacets(response.data);
        });
    }

    public fetchPartnersList() {
        const endpoint = `${this.mcNamespace}/partners`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers).then((response) => {
          return facetSerializer.partnerListModelSerializer(response.data);
        });
    }

    /**
     * Gets the audience list information
     * @returns {Promise.<[]>}
     */
    public readAudiences() {
        const namespace = this.lookupNamespace;
        const url = `${namespace}/audience`;
        const headers = http.getTokenHeaders();
        return http.get(url, headers).then((response) => {
            return facetSerializer.normalizeReadAudiences(response.data);
        });
    }

    public fetchSubscribedToolRegistryList(params: any = {}) {
        params = {
            offset: 0,
            limit: 10,
            registry_type: 'content_registry'
        }
        const endpoint = `${this.lrNamespace}/subscribed/learning-tools`;
        const headers: any = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
          return facetSerializer.normalizeSubscribe(response.data);
        }).catch((error: any) => {
          return { message: error };
        });
    }

    public readLanguages() {
        const namespace = this.lookupNamespace;
        const url = `${namespace}/languages`;
        const headers = http.getTokenHeaders();
        return http.get(url, headers).then((response) => {
            return facetSerializer.normalizeLanguage(response.data);
        });
    }

    public readEducationalUse() {
        const namespace = this.lookupNamespace;
        const url = `${namespace}/educational-use`;
        const headers = http.getTokenHeaders();
        return http.get(url, headers).then((response) => {
            return facetSerializer.normalizeEducationUse(response.data);
        });
    }

    public readDepthOfKnowledge() {
        const namespace = this.lookupNamespace;
        const url = `${namespace}/dok`;
        const headers = http.getTokenHeaders();
        return http.get(url, headers).then((response) => {
            return facetSerializer.normalizeDok(response.data);
        });
    }

    public postLTIData(contentList: any) {
        const endpoint = `${this.deeplinkNamespace}/deeplinking/urls`;
        const headers = http.getTokenHeaders();
        return http.post(endpoint,headers, contentList).then(response => {
            return response.data;
        });
    }

}

export const facetAPI = FacetAPI.instance;