import { CAP_CONTENT_MAPPING_LIST, DEFAULT_IMAGES } from "@/constants/constants";
import { globalConfig } from "@/global-config";

export class SearchSerializer {
    static INSTANCE = new SearchSerializer();

    static get instance() {
        return this.INSTANCE;
    }

    public normalizeSearchPayload(payload: any) {
        const searchResults = payload.searchResults || [];
        const totalHitCount = payload.totalHitCount || 0;
        const configDetail = globalConfig.configDetails;
        searchResults.forEach((result: any) => {
            result.thumbnail = result.thumbnail ? configDetail.contentCdn + result.thumbnail : DEFAULT_IMAGES["content-image"];
            if(!result.creator) {
                result.creator = this.normalizeUsers(result);
            }
        });
        return {
            searchResults,
            totalHitCount
        }
    } 

    public normalizeCapSearchContent(payload: any, includeTypes = [], isMergeResponse = true) {
        const contents = payload.contents || {};
        const mapList: any = CAP_CONTENT_MAPPING_LIST;
        const resultSet: any = {
            searchResults: [],
            totalHitCount: 0
        };
        
        if(includeTypes.length && isMergeResponse) {
            includeTypes.forEach(type => {
                const activeContent = contents[mapList[type]];
                if(activeContent) {
                    const { searchResults = [], stats = {} } = activeContent;
                    const configDetail = globalConfig.configDetails;
                    searchResults.forEach((result: any) => {
                        result.thumbnail = result.thumbnail ? configDetail.contentCdn + result.thumbnail : DEFAULT_IMAGES["content-image"];
                    });
                    resultSet.searchResults = [...resultSet.searchResults, ...searchResults];
                    resultSet.totalHitCount += stats.totalHitCount;
                }
                resultSet[mapList[type]] = contents[mapList[type]]
            })
            return resultSet;
        }else {
            return contents;
        }
    }

    private normalizeUsers(payload: any) {
        const userDetails = {
            id: payload.creatorId,
            firstname: payload.userFirstName,
            lastname: payload.userLastName,
            profileImage: payload.userProfileImage,
            usernameDisplay: payload.usernameDisplay
        };
        
        return userDetails;
    }

}

export const searchSerializer = SearchSerializer.instance;