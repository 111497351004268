
export const DEFAULT_IMAGES = {
    'content-image': '/assets/gooru/images/image.jpeg'
}

/**
 * @property { Array } COLLECTION_TYPE_CONSTANT help to identify the filter key based on format  
 */
export const COLLECTION_TYPE_CONSTANT:{[key: string]: string} = {
    resource: 'flt.contentFormat',
    question: 'flt.resourceFormat',
    collection: 'flt.collectionType',
    assessment: 'flt.collectionType',
    'offline-activity': 'flt.collectionType',
    'resource-sub': 'flt.resourceFormat',
    'question-sub': 'flt.questionType'
}

/**
 * @property { Array } CONTENT_SOURCE_LIST has the list of content source  
 */
export const CONTENT_SOURCE_LIST:Array<{name: string, key: string}> = [
    {
        name: 'Cap Content',
        key: 'gooru-catalog'
    },
    {
        name: 'My Content',
        key: 'my-content'
    }
];

export const SEARCH_CONTENT_API_LIST = {
    collection: {
        apiMethod: 'searchCollections'
    },
    assessment: {
        apiMethod: 'searchAssessments'
    },
    'offline-activity': {
        apiMethod: 'searchOfflineActivities'
    },
    question: {
        apiMethod: 'searchQuestions'
    },
    resource: {
        apiMethod: 'searchResources'
    },
    rubric: {
        apiMethod: 'searchRubrics'
    },
    course: {
        apiMethod: 'searchCourses'
    }
};


export const CONTENT_SOURCE_API_LIST = {
    'my-content': SEARCH_CONTENT_API_LIST,
    'cap-content': {
        apiMethod: 'searchCapContent',
        hasPayloadGenerator: true
    }
};

export const RESOURCE_SUB_CONTENT_TYPE_LIST = [
    {
        name: 'Video',
        keyName: 'video'
    },
    {
        name: 'Audio',
        keyName: 'audio'
    },
    {
        name: 'Interactive',
        keyName: 'interactive'
    },
    {
        name: 'Text',
        keyName: 'text'
    },
    {
        name: 'Webpage',
        keyName: 'webpage'
    }
];

export const QUESTION_SUB_CONTENT_TYPE_LIST = [
    {
        name: 'Multiple Answer',
        keyName: 'multiple-answer'
    },
    {
        name: 'Multiple Choice',
        keyName: 'multiple-choice'
    },
    {
        name: 'Drag and Drop',
        keyName: 'drag-and-drop'
    },
    {
        name: 'Multi Select',
        keyName: 'multi-select'
    }
];

export const CONTENT_TYPE_LIST = [
    {
        name: 'Course',
        key: 'course',
        modalKey: 'content.modals.gru-course-new'
    },
    {
        name: 'Collection',
        key: 'collection',
        modalKey: 'content.modals.gru-collection-new'
    },
    {
        name: 'Assessment',
        key: 'assessment',
        modalKey: 'content.modals.gru-assessment-new'
    },
    {
        name: 'External Collection',
        key: 'collection-external',
        modalKey: 'content.modals.gru-external-collection-new'
    },
    {
        name: 'External Assessment',
        key: 'assessment-external',
        modalKey: 'content.modals.gru-external-assessment-new'
    },
    {
        name: 'Offline Activity',
        key: 'offline-activity'
    },
    {
        name: 'Resource',
        key: 'resource',
        modalKey: 'content.modals.gru-resource-new'
    },
    {
        name: 'Questions',
        key: 'question',
        modalKey: 'content.modals.gru-question-new'
    },
    {
        name: 'Rubrics',
        key: 'rubric',
        modalKey: 'content.modals.gru-rubric-new'
    },
];

export const CAP_CONTENT_MAPPING_LIST = {
    collection: 'collections',
    assessment: 'assessments',
    course: 'courses',
    lesson: 'lessons',
    resource: 'resources',
    question: 'questions',
    'offline-activity': 'offlineActivities'    
}
