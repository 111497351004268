import FacetContentLoaderComponent from '@/components/facet-content-loader/facet-content-loader.vue';
import FacetOverlay from '@/components/facet-overlay/facet-overlay.vue';
import FacetsSearchFiltersComponent from '@/components/facet-search-filters/facet-search-filters.vue';
import FacetsSearchFilterHeaderComponent from '@/components/facets-search-filters-header/facets-search-filters-header.vue';
import FacetInitializer from '@/setup/facet-initializer';
import { Vue, Component, Prop } from 'vue-property-decorator';
import SearchEngine from '../../setup/search-engine';

@Component({
    name: 'dashboard-page',
    components: {
        'facet-search-filter': FacetsSearchFiltersComponent,
        'facet-search-filter-blk': FacetsSearchFilterHeaderComponent,
        'facet-content-loader': FacetContentLoaderComponent,
        'facet-overlay': FacetOverlay
    }
})

export default class DashboardPage extends Vue {

    // --------------------------------------------------------------
    // Properites
    
    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    public get contentResults() {
        return this.searchEngine.contentResults;
    }

    public get isLoading() {
        return this.searchEngine.isLoading;
    }

    public get requestBody(){
        return this.searchEngine.getRequestBody;
    }

}