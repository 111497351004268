import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FacetFormLoader from '../facet-form-loader/facet-form-loader.vue';

@Component({
    name: 'facet-search-filters',
    components: {
        'facet-search-form': FacetFormLoader 
    }
})
export default class FacetsSearchFiltersComponent extends Vue {

    // ------------------------------------------------------
    // Properties

    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    /**
     * @property { Object } contentSources has list of content sources
     */
     public get contentSources() {
        return this.searchEngine.contentSource;
    }


    public get facetSearchFilter() {
        return this.facetSetup.facetsList;
    }

    // ------------------------------------------------------
    // Hooks


    // ------------------------------------------------------
    // Methods

}