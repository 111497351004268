import { FacetsFilterKeyModel } from "@/models/facets";

export default class FacetItem {
    constructor(
        public name: string,
        public filterKey: FacetsFilterKeyModel = {} as FacetsFilterKeyModel,
        public isEditable: boolean = true,
        public isSelected: boolean = true,
        public items: any = [],
        public selectedItems: any = [],
    ) {}
}
