import { CONTENT_TYPE_LIST } from '@/constants/constants';
import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';
import NavSideMenuComponent from '../nav-side-menu/nav-side-menu.vue';

@Component({
    name: 'nav-header',
    components: {
        'side-menu-panel': NavSideMenuComponent
    }
})

export default class NavHeaderComponent extends Vue {

    // -----------------------------------------------------------
    // Properties

    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    public get systemFilter() {
        return this.facetSetup.systemFacetItem;
    }

    public get activeFilters() {
        return this.searchEngine.getRequestBody;
    }

    public get isMyContent() {
        return this.activeFilters['content-source'] === 'my-content';
    }

    public searchFocus = false;

    public get activeCategory() {
        return this.facetSetup.activeCategory || {};
    }

    public contentTypes: any = CONTENT_TYPE_LIST;
  
    // -----------------------------------------------------------
    // Hooks

    // -----------------------------------------------------------
    // Actions

    public onFocusOut() {
       this.searchFocus = false;
    }

    public onFocusIn() {
        this.searchFocus = true;
    }

    public onSearchItem() {
        this.searchEngine.resetData();
        this.searchEngine.searchContents();
    }

    public onSelectContentType(contentType: any) {
        this.$root.$emit("onSelectContentType", contentType);
    }

    // ------------------------------------------------------------
    // Methods
 

}