import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'facet-checkbox'
})
export default class FacetCheckbox extends Vue {

    // -------------------------------------------------
    // Properties

    @Prop()
    public items: any;

    @Prop()
    public labelKey: any;

    @Prop()
    public valueKey: any;

    @Prop()
    public filterKey: any;

    @Prop()
    public value: any;

    public selectedItems: any = [];

    public activeSubMenu: any = {};

    public get selectedSubMenus() {
        const selectedItems = this.selectedItems;
        return  !Array.isArray(selectedItems) ? (this.activeSubMenu[this.selectedItems] || []) : [];
    }

    // -------------------------------------------------
    // Hooks

    public created() {
        this.selectedItems = this.value;
    }

    
    // -------------------------------------------------
    // Actions

    public onSelectItem(item: any) {
        const value = item[this.valueKey];
        const selectedItems = this.selectedItems;
        if(this.filterKey.hasMultiSelect) {
            const activeIndex = selectedItems.indexOf(value);
            activeIndex === -1 ? selectedItems.push(value) : selectedItems.splice(activeIndex, 1);
        }else {
            this.selectedItems = value;
        }
        this.$emit('input', this.selectedItems);
    }

    public onSelectSubMenu(item: any) {
        const value = item[this.valueKey];
        const selectedItems = this.selectedItems;
        const selectedSubmenus = this.activeSubMenu[selectedItems] || [];
        const activeIndex = selectedSubmenus.indexOf(value);
        activeIndex === -1 ? selectedSubmenus.push(value) : selectedSubmenus.splice(activeIndex, 1);
        this.$set(this.activeSubMenu, selectedItems,selectedSubmenus);
        this.$emit('onSubMenu', selectedItems, selectedSubmenus);
    }
}