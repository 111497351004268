import { render, staticRenderFns } from "./facet-overlay.vue?vue&type=template&id=74fa7c84&scoped=true&"
import script from "./facet-overlay.ts?vue&type=script&scoped=true&lang=ts&"
export * from "./facet-overlay.ts?vue&type=script&scoped=true&lang=ts&"
import style0 from "./facet-overlay.scss?vue&type=style&index=0&id=74fa7c84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74fa7c84",
  null
  
)

export default component.exports