import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'facet-active-filter'
})
export default class FacetActiveFilter extends Vue {

    // ----------------------------------------------------------------------
    // Properties

    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    @Prop({default: 4})
    public tagLimit!: number;

    public get hasMoreTags() {
        const activeTags = this.activeFacetsList || [];
        return activeTags.length > this.tagLimit;
    }

    public get requestBody() {
        return this.searchEngine.requestBody;
    }

    public get facetsList() {
        return this.facetSetup.facetsList;
    }

    public get systemFacets() {
        return this.facetSetup.systemFacetItem;
    }

    public get activeFacetsList() {
        return this.searchEngine.getActiveFacetTags;
    }

    public appConfig = this.$globalConfig.configDetails;

    public get isDeepLink() {
        return this.appConfig.isDeepLink;
    }

    public get contentType() {
        const sources = this.facetsList['content-type'];
        const items = sources ? sources.items : [];
        const filterKey = sources && sources.filterKey;
        return filterKey ? items.find(
            (item: any) => item[filterKey.valueKey] === this.requestBody['content-type'])[filterKey.labelKey] : null;
    }

    // -----------------------------------------------------------------------
    // Hooks




    // -----------------------------------------------------------------------
    // Actions

    public onClear(filter: any) {
       this.searchEngine.clearRequestPayload(filter);
    }

    public onSubmitData() {
        this.searchEngine.onSubmitLTI().then(formData => {
            const body = document.body;
            body.insertAdjacentHTML('beforeend', formData);
            document.getElementById('ltiAuthForm')?.remove();
        });
    }

    // -----------------------------------------------------------------------
    // Methods




}