export default class PayloadGenerator {
    // -----------------------------------------------------------------
    // Properties

    constructor(private format: string, private query: string) {
        // this.includeContentTypes = [format];
    } 

    /**
     * @property { Array } filterKeys help to identity the list of filter metadata 
     */
    public filterKeys = ['audience','fwCode','includeContentTypes','standard'];

    /**
     * NOTE: temprary fix we need to update this from admin UI 
     */
    private stringFields = ['dok','educational','audiences','publishers','products']

    // -----------------------------------------------------------------
    // Methods

    /**
     * @function buildCapRequestBody help to build the cap request body 
     */
    public buildCapRequestBody(requestBody: any, facetList: any) {
        const payloadData = {
            excludeNonCrosswalkableContents: true,
            filters: this.buildFilterRequestBody(requestBody, facetList),
            limit: requestBody.limit || 10,
            pageNum: requestBody.start || 1,
            query: requestBody.terms || '*'
        }
        return payloadData;
    }

    /**
     * @function buildFilterRequestBody help to build the filter request body 
     */
    public buildFilterRequestBody(requestBody: any, facetsList: any) {
        const filterBody: any = {
            'subjectClassification': requestBody.category && [requestBody.category],
            'subject': requestBody.subject && [requestBody.subject],
            'domain': requestBody.domain,
            'taxGrade': requestBody.level ? [requestBody.level] : null,
            'fwCode': requestBody.framework,
            'standard': requestBody.competency
        };
        Object.keys(filterBody).forEach(item => {
            if(!filterBody[item]){
                delete filterBody[item];
            }
        });
        const facetsKeyList = Object.values(facetsList);
        facetsKeyList.forEach((facetObj: any) => {
            if(facetObj) {
                const { filterKey } = facetObj;
                const activeFilters = requestBody[filterKey.facetKey];
                if(filterKey.cap !== 'types' && activeFilters && activeFilters.length) {
                    filterBody[filterKey.cap] = this.stringFields.includes(filterKey.facetKey) ? activeFilters.toString() : activeFilters
                }else{
                    filterBody.includeContentTypes = [requestBody['content-type']];
                }
                
            }
        });
        // this.subContentFormat(f)
        delete filterBody.scopeKey;
        return filterBody;
    }

    /**
     * @function dynamicSetter help to update the property value without mutated 
     */
    public dynamicSetter(key: string, value: string | number) {
        (this as any)[key] = value;
    }

    public subContentFormat(filterBody: any, requestBody: any) {
        const format = requestBody['content-type'];
        const subFormat = requestBody[`${format}-sub`];
        if(subFormat) {
            filterBody.subFilterFormat = subFormat
        }
    }
}