import { ClassificationModel, DomainListModel, FrameworkModel, GradeListModel, SubjectModel } from "@/models/taxonomy";

export class TaxonomySerializer {
    
    private static INSTANCE = new TaxonomySerializer();

    static get instance() {
        return this.INSTANCE;
    }

    public normalizeClassification(payload: any) {
        const categoriesList = payload.subject_classifications || [];
        return categoriesList.map((category: any) => {
            return {
                id: category.id,
                code: category.code,
                title: category.title,
                subjects: []
            } as ClassificationModel
        });
    }

    public normalizeSubjects(payload: any) {
        const subjects = payload.subjects;
        return subjects.map((subject: any) => {
            return {
                id: subject.id,
                code: subject.code,
                title: subject.title,
                frameworks: this.normalizeFrameworks(subject)
            } as SubjectModel
        });
    }

    private normalizeFrameworks(payload: any) {
        const frameworks = payload.frameworks || [];
        return frameworks.map((framework: any) => {
            return {
                id: framework.standard_framework_id,
                title: framework.title,
                taxonomySubjectId: framework.taxonomy_subject_id,
                taxonomySubjectTitle: framework.taxonomy_subject_title,
            } as FrameworkModel
        })
    }

    public normalizeGradeList(payload: any) {
        const grades = payload.courses || [];
        return grades.map((grade: any) => {
            return {
                id: grade.id,
                title: grade.title,
                sequence: grade.sequence_id
            } as GradeListModel
        })
    }

    public normalizeDomainList(payload: any) {
        const domains = payload.domains || [];
        return domains.map((domain: any) => {
            return {
                id: domain.id,
                title: domain.title,
                sequence: domain.sequence_id,
                defaultTaxonomyDomainId: domain.default_taxonomy_domain_id
            } as DomainListModel
        })
    }

    public normalizeTopicList(payload: any) {
        const topics = payload.topics || [];
        return topics;
    }

    public normalizeCompetencies(payload: any) {
        const competencies = payload.competencies || [];
        return competencies;
    }
}

export const taxonomySerializer = TaxonomySerializer.instance;