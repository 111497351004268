
import { Vue, Component } from 'vue-property-decorator';
import { SessionModel } from './models/session';
import LayoutPage from './views/layout-page/layout-page.vue';

@Component({
    name: 'layout-page',
    components: {
      LayoutPage
    }
})
export default class AppPage extends Vue {
  
  // --------------------------------------------------------------------
  // Properties

  // eslint-disable-next-line 
  public items: Map<string, any> = new Map();

  // --------------------------------------------------------
  // Hooks

  public created() {
    if(this.$options?.parent?.$data?.items) {
      this.items = this.$options?.parent?.$data?.items;
      this.initiateSessionData();
    }
  }

  // --------------------------------------------------------
  // Methods

  private initiateSessionData() {
    const items = this.items;
    this.$globalConfig.setConfig = items.get('configDetail');
    const session: SessionModel = items.get('session');
    this.$globalConfig.setSessionData = session;
  }

}
