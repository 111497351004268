import { facetAPI } from "@/providers/apis/facet";
import { taxonomyAPI } from "@/providers/apis/taxonomy";
import axios from "axios";
import SearchEngine from "./search-engine";

/**
 * Help to handle the API call related to facets
 */
export default class FacetInitializer {

    // ---------------------------------------------------------
    // Properties

    public constructor(private searchEngine: SearchEngine){
        this.searchEngine.facetSetup = this;
    }

    /**
     * @property { Array } filterNames Help to understand the filter list of items 
     */
    public filterNames = ['framework','level', 'domain','topic','competency'];

    /**
     * @property { Array } filterNames Help to understand the filter action to append
     */
    public filterActions: any = {
        framework: this.onSelectFramework.bind(this),
        level: this.onSelectGradeLevel.bind(this),
        domain: this.onSelectDomain.bind(this),
        topic: this.onSelectTopic.bind(this)
    };

    public facetFilterAtions: any = {
        publishers: this.fetchPublishersList.bind(this),
        audiences: this.fetchAudienceList.bind(this),
        products: this.fetchProductsList.bind(this),
        language: this.readLanguages.bind(this),
        educational: this.readEducationalUse.bind(this),
        dok: this.readDok.bind(this)
    }


    private categoryList: any = [];

    public set setCategories(categories: any) {
        this.categoryList = categories;
    } 

    public get getCategories() {
        return this.categoryList;
    }

    private dropdownFilters: any = {};

    public get getDropdownFilter() {
        return this.dropdownFilters;
    }

    public set setDropdownFilter(dropdownFilters: any) {
        this.dropdownFilters = dropdownFilters;
    }

    public activeCategory: any = null;

    public facetsList: any = [];

    public systemFacetItem: any = null;

    // ---------------------------------------------------------------
    // Actions

    public onSelectFramework(requestBody: any) {
        this.fetchGradeList(requestBody);
    }

    public onSelectGradeLevel(requestBody: any) {
        this.fetchDomainList(requestBody)
    }
  
    public onSelectDomain(requestBody: any) {
        this.fetchTopicList(requestBody);
    }

    public onSelectTopic(requestBody: any) {
        this.fetchCompetenciesByTopic(requestBody);
    }

    // ---------------------------------------------------------------
    // Methods

    public loadData() {
        axios.all([
            this.fetchFacetsList(),
            taxonomyAPI.fetchClassifications()
        ]).then(axios.spread((facets, categories) => {
            this.categoryList = categories;
            this.setDropdownFilter = this.createFilter();
            this.initializeSearchFilter(categories);
            this.searchEngine.resetData();
            this.searchEngine.searchContents();
        }))
    }

    public fetchSubjectsByCategory(category: any) {
        const requestBody = this.searchEngine.requestBody;
        const getDropdownFilter = this.getDropdownFilter;
        return taxonomyAPI.fetchSubjectsList({classification_type: category.id}).then(subjects => {
            category.subjects = subjects;
            if(requestBody.subject) {
                const activeSubject = subjects.find((item: any) => item.id === requestBody.subject);
                if(activeSubject){
                    getDropdownFilter.framework.items = activeSubject.frameworks;
                }
            }
        });
    }

    private createFilter() {
        const filterItems: any = {};
        const filterNames = this.filterNames;
        filterNames.forEach(keyName => {
            filterItems[keyName] = {
                key: keyName,
                items: [],
                eventAction: this.filterActions[keyName]
            }
        });
        return filterItems;
    }

    private fetchGradeList(requestBody: any) {
        const params = {
            subject: requestBody.subject,
            fwCode: requestBody.framework
        }
        return taxonomyAPI.fetchGradesBySubject(params).then((gradeList) => {
            this.dropdownFilters.level.items = gradeList; 
        });
    }

    private fetchDomainList(requestBody: any) {
        const params = {
            subject: requestBody.subject,
            fwCode: requestBody.framework,
            courseId: requestBody.level
        }
        return taxonomyAPI.fetchDomainByGrade(params).then((domainList) => {
            this.dropdownFilters.domain.items = domainList; 
        });
    }

    private fetchTopicList(requestBody: any) {
        const params = {
            subject: requestBody.subject,
            fwCode: requestBody.framework,
            courseId: requestBody.level,
            domainId: requestBody.domain
        }
        return taxonomyAPI.fetchTopicByDomain(params).then((domainList) => {
            this.dropdownFilters.topic.items = domainList; 
        });
    }

    private fetchCompetenciesByTopic(requestBody: any) {
        const params = {
            subject: requestBody.subject,
            fwCode: requestBody.framework,
            courseId: requestBody.level,
            domainId: requestBody.domain,
            topicId: requestBody.topic
        }
       return taxonomyAPI.fetchCompetenciesByTopic(params).then((domainList) => {
            this.dropdownFilters.competency.items = domainList; 
        });
    }

    private fetchFacetsList() {
        return facetAPI.fetchFacetsList().then((facetsList) => {

            // Note: Here we remove the system filter from the facet settings 
            if(facetsList.system) {
                this.systemFacetItem = facetsList.system;
                this.buildSystemFilter();
                delete facetsList.system;
            }

            // Apply facet items without system facet items 
            this.searchEngine.facetsList = facetsList;
            return this.facetsActionMapping(facetsList);
        });
    }

    public fetchPublishersList() {
        return facetAPI.fetchPartnersList();
    }

    public fetchAudienceList() {
        return facetAPI.readAudiences();
    }

    public fetchProductsList() {
        return facetAPI.fetchSubscribedToolRegistryList();
    }

    public readLanguages() {
        return facetAPI.readLanguages();
    }

    public readEducationalUse() {
        return facetAPI.readEducationalUse();
    }

    public readDok() {
        return facetAPI.readDepthOfKnowledge();
    }

    /**
     * @func facetsActionMapping help to identify the method to load the facets items
     */
    private facetsActionMapping(facetsList: any) {
        const facetsKeys = Object.keys(facetsList);
        const facetFilterAtions = this.facetFilterAtions;
        facetsKeys.forEach(facetKey => {
            const apiMethod = facetFilterAtions[facetKey];
            if(apiMethod) {
                apiMethod().then((facetItems: any) => {
                    facetsList[facetKey].items = facetItems;
                    // Note:- If  required to show only the selected items use below logic
                    // facetsList[facetKey].items = this.filterSelectedFacets(facetItems,
                    //      facetsList[facetKey].selectedItems, facetsList[facetKey].filterKey);

                });
            }
        });
        this.facetsList = this.buildRequestPayload(facetsList);
        return this.facetsList
    }

    /**
     * @func  buildRequestPayload Method helps to build the existing filter and event append
     */
    private buildRequestPayload(facetsList: any) {
        const activeFilters = this.searchEngine.requestBody;
        delete facetsList.categories;
        const facetsKeys = Object.keys(facetsList);
        facetsKeys.forEach(facetKey => {
            const activeFacet = facetsList[facetKey];
            const { filterKey } = activeFacet;
            activeFilters[facetKey] = filterKey.hasMultiSelect ? [] : activeFilters[facetKey];
            activeFacet.items = this.filterSelectedFacets(activeFacet.items, activeFacet.selectedItems, filterKey);
            activeFacet.eventBind = {
                input: this.searchEngine.facetSearchEvent.bind(this.searchEngine)
            }
        });
        return facetsList;
    }

    /**
     * @property { Array }  buildSystemFilter help to filter the selected faceted items from the orginal items
     */
    private buildSystemFilter() {
        const { items, filterKey } = this.systemFacetItem;
        this.filterNames = items.map((item: any) => item[filterKey.valueKey]);
    }

    /**
     * @property { Array }  filterSelectedFacets help to filter the selected faceted items
     */
    private filterSelectedFacets(items: any, selectedItems: any, filterKey: any) {
        return items.filter((item: any) => selectedItems.indexOf(item[filterKey.valueKey]) !== -1);
    }

    private async initializeSearchFilter(categories: any) {
        const requestBody = this.searchEngine.requestBody;
        const dropdownFilter = this.getDropdownFilter;
        const dropdownItems: any = Object.values(dropdownFilter);
        if(requestBody.category) {
            this.activeCategory = categories.find((item: any) => item.id === requestBody.category);
            await this.fetchSubjectsByCategory(this.activeCategory);
        }
        for await (const item of dropdownItems) {
            if(requestBody[item.key]){
                await item.eventAction(requestBody);
            }
        }
        this.searchEngine.buildActiveFacetItems();
    }
}