import { SessionModel } from "./models/session";

export class GlobalConfig {
    
    static INSTANCE = new GlobalConfig();

    public static get instance() {
        return this.INSTANCE;
    }

    public configDetails: any = {};

    private sessionToken: string | undefined = undefined;   

    private url: string | undefined = process.env.VUE_APP_API_URL;

    private sessionData: SessionModel | undefined = {} as SessionModel;
    
    public set setToken(token: string) {
        this.sessionToken = token
    }

    public get getToken() {
        return this.sessionToken;
    }

    public set setUrl(url: string) {
        this.url = url;
    }

    public get getUrl() {
        return this.url;
    }

    public set setSessionData(session: SessionModel) {
        this.sessionData = session;
    }

    public get getSessionData() {
        return this.sessionData;
    }

    public set setConfig(config: any) {
        this.configDetails = config;
        this.setUrl = config.endpoint;
        this.setToken = config.sessionToken;
    }
    

}

export const globalConfig = GlobalConfig.instance;