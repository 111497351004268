import { http } from "../http";
import { searchSerializer } from "../serializers/search";

export class SearchAPI {
    
    static INSTANCE = new SearchAPI();

    static get instance() {
        return this.INSTANCE;
    }

    private namespace = 'gooru-search/rest/v2/search';

    private namespacecap = 'gooru-search/rest/v1/cap/content/search';

    public searchCollections(params: any) {
        const endpoint = `${this.namespace}/scollection`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchAssessments(params: any) {
        const endpoint = `${this.namespace}/scollection`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchOfflineActivities(params: any) {
        const endpoint = `${this.namespace}/scollection`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchQuestions(params: any) {
        const endpoint = `${this.namespace}/resource`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchResources(params: any) {
        const endpoint = `${this.namespace}/resource`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchRubrics(params: any) {
        const endpoint = `${this.namespace}/rubric`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchCourses(params: any) {
        const endpoint = `${this.namespace}/course`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return searchSerializer.normalizeSearchPayload(response.data);
        });
    }

    public searchCapContent(params: any) {
        const endpoint = this.namespacecap;
        const headers = http.getTokenHeaders();
        return http.post(endpoint, headers, params).then(response => {
            return searchSerializer.normalizeCapSearchContent(response.data, params.filters.includeContentTypes);
        });
    }

}

export const searchAPI = SearchAPI.instance;