import { http } from "../http";
import { taxonomySerializer } from "../serializers/taxonomy";

export class TaxonomyAPI {

    private static INSTANCE = new TaxonomyAPI();

    static get instance() {
        return this.INSTANCE;
    }
    
    private namespace = 'api/nucleus/v1/taxonomy';

    private txNamespace = 'api/ds/users/v2/tx';

    private dsNamespace = 'api/ds/users';

    public fetchClassifications() {
        const endpoint = `${this.namespace}/classifications`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers).then((response) => {
            return taxonomySerializer.normalizeClassification(response.data);
        });
    }

    public fetchSubjectsList(params: {classification_type: string}) {
        const endpoint = `${this.namespace}/subjects`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then((response) => {
            return taxonomySerializer.normalizeSubjects(response.data);
        });
    }
    
    public fetchGradesBySubject(params: {subject: string, fwCode: string}) {
        const endpoint = `${this.namespace}/frameworks/${params.fwCode}/subjects/${params.fwCode}.${params.subject}/courses`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then(response => {
            return taxonomySerializer.normalizeGradeList(response.data);
        });
    }

    public fetchDomainByGrade(params: {fwCode: string, subject: string, courseId: string}) {
        const endpoint = `${this.namespace}/frameworks/${params.fwCode}/subjects/${params.fwCode}.${params.subject}/courses/${params.courseId}/domains`;
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers, params).then(response => {
            return taxonomySerializer.normalizeDomainList(response.data);
        });
    }

    public fetchTopicByDomain(params: {fwCode: string, subject: string, courseId: string, domainId: string}) {
        const endpoint =  `${this.namespace}/frameworks/${params.fwCode}/subjects/${params.subject}/courses/${params.courseId}/domains/${params.domainId}/topics`
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers).then(response => {
            return taxonomySerializer.normalizeTopicList(response.data);
        })
    }

    public fetchCompetenciesByTopic(params: {fwCode: string, subject: string, courseId: string, domainId: string, topicId: string}) {
        const endpoint =  `${this.namespace}/frameworks/${params.fwCode}/subjects/${params.subject}/courses/${params.courseId}/domains/${params.domainId}/topics/${params.topicId}/competencies`
        const headers = http.getTokenHeaders();
        return http.get(endpoint, headers).then(response => {
            return taxonomySerializer.normalizeCompetencies(response.data);
        })
    }
    
    
}

export const taxonomyAPI = TaxonomyAPI.instance;