import { facetAPI } from "@/providers/apis/facet";

export default class LTIInitializer {

    // ------------------------------------------------------------------
    // Properties

    public deeplinkContent: any = [];


    // ----------------------------------------------------------------
    // Methods

    public postLTIContent() {
        const requestBody = this.buildPayload();
        return facetAPI.postLTIData(requestBody);
    }

    private buildPayload() {
        const baseUrl = window.location.origin;
        const deeplinkContent = this.deeplinkContent;
        const payloadData: any = {
            deeplinkingUrl: []
        };
        deeplinkContent.forEach((content: any) => {
            const { deeplinkingUrl } = payloadData;
            const deeplinkPayload: any = {
                launchTarget: 'LaunchCatalog',
                url: `${baseUrl}/api/nucleus-lti/v1/lti/v1p3/launch`,
                contentType: content.format,
                contentId: content.id,
                contentTitle: content.title
            };
            deeplinkingUrl.push(deeplinkPayload);
        });
        return payloadData;
    }

}