import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'facet-search-card'
})

export default class FacetSearchCardComponent extends Vue {

    // --------------------------------------------------------
    // Properties

    @Prop()
    public content: any;

    @Prop()
    public searchEngine!: SearchEngine;

    public get requestBody() {
        return this.searchEngine.requestBody;
    }

    public get deepLinkResource() {
        return this.searchEngine.deepLinkResources;
    }

    public appConfig = this.$globalConfig.configDetails;

    

    // --------------------------------------------------------
    // Actions

    public onPlayContent(content: any) {
        this.$root.$emit("onPlayContent", content);
    }

    public onEditContent(content: any) {
        this.$root.$emit("onEditContent", content);
    }

    public onAddContent(content: any) {
        this.$root.$emit('onAddContent', content);
    }

    public onSelectResource(content: any) {
        const deepLinkResource = this.deepLinkResource;
        const activeIndex =  deepLinkResource.indexOf(content.id);
        activeIndex !== -1 ? deepLinkResource.splice(activeIndex, 1) : deepLinkResource.push(content.id);
    }
    
}