import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'facet-overlay',
})
export default class FacetOverlay extends Vue {

    // --------------------------------------------------------
    // Properties

    @Prop()
    public isLoading!: boolean;

}