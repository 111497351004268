import { render, staticRenderFns } from "./facet-active-filter.vue?vue&type=template&id=cd8a0b1e&scoped=true&"
import script from "./facet-active-filter.ts?vue&type=script&lang=ts&"
export * from "./facet-active-filter.ts?vue&type=script&lang=ts&"
import style0 from "./facet-active-filter.scss?vue&type=style&index=0&id=cd8a0b1e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd8a0b1e",
  null
  
)

export default component.exports