import LayoutPage from '@/views/layout-page/layout-page';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: LayoutPage
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: {
  //     template: "<div>Working fine</div>"
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
