import { render, staticRenderFns } from "./facet-checkbox.vue?vue&type=template&id=1abcd413&scoped=true&"
import script from "./facet-checkbox.ts?vue&type=script&lang=ts&"
export * from "./facet-checkbox.ts?vue&type=script&lang=ts&"
import style0 from "./facet-checkbox.scss?vue&type=style&index=0&id=1abcd413&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1abcd413",
  null
  
)

export default component.exports