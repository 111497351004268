import NavHeaderComponent from '@/components/nav-header/nav-header.vue';
import { ConfigurationModel } from '@/models/configuration';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DashboardPage from '../dashboard/dashboard-page.vue';
import SearchEngine from '../../setup/search-engine';
import FacetInitializer from '@/setup/facet-initializer';

@Component({
    name: 'layout-page',
    components: {
        'dashboard-page': DashboardPage,
        'nav-header': NavHeaderComponent,
        'dashboard': DashboardPage
    }
})

export default class LayoutPage extends Vue {

    // --------------------------------------------------------
    // Properties

    @Prop({default: null})
     // eslint-disable-next-line 
    public items!: Map<string, any>;

    public configDetail: ConfigurationModel = {} as ConfigurationModel;

    public searchEngine = new SearchEngine();

    public facetSetup = new FacetInitializer(this.searchEngine);

    // --------------------------------------------------------
    // Hooks

    public created() {
        this.initialize();
    }

 
    // --------------------------------------------------------
    // Actions
    

    // --------------------------------------------------------
    // Methods

    public initialize() {
        this.preLoadedPayload();
        this.facetSetup.loadData();
    }

    /**
     * @func preLoadedPayload Help to load the applied request payload 
     */
    public preLoadedPayload() {
        const items = this.items;
        const requestBody = this.searchEngine.requestBody;
        const requestPayload = items.get('requestPayload');
        if(requestPayload) {
            this.searchEngine.requestBody = {...requestBody, ...requestPayload};
        }
    }

    
}