import FacetItem from "@/setup/facet-item";

export class FacetSerializer {
    
    private static INSTANCE = new FacetSerializer();

    static get instance() {
        return this.INSTANCE
    }

    public normalizeFacets(payload: any) {
        const facets = payload.facets || [];
        const facetsList: any = {};
        facets.forEach((item: any) => {
            const filterKey = item.filterKey;
            const selectedOptions = item.selectedOptions;
            facetsList[filterKey.facetKey] = new FacetItem(item.labelKey, filterKey,
                item.isEditable, item.isSelected, item.options, selectedOptions);
        });
        return facetsList;
    }


    public partnerListModelSerializer(payload: any){
        const partnersTypes = Object.keys(payload) || [];
        let partnersList: any = [];
        if(partnersTypes) {
            partnersTypes.forEach((partnerType) => {
                if(Array.isArray(payload[partnerType])) {
                    partnersList = [...partnersList, ...payload[partnerType]];
                }
            });
        }
        return partnersList;
    }

    public normalizeReadAudiences(payload: any) {
        const normalizedAudiences: any = [];
        if (Array.isArray(payload.audience)) {
            payload.audience.map((audience: any) => {
                const audienceData = {
                    label: audience.label,
                    sequence_id: audience.sequence_id,
                    id: audience.id,
                };
                normalizedAudiences.push(audienceData);
            });
        }
        return normalizedAudiences;
    }

    public normalizeSubscribe(payload: any) {
        const subscribedContents  = payload.subscribedContents || [];
        subscribedContents.map((item: any) => {
            item.id = item.productId;
            item.name = item.productName;
            return item;
        });
        return subscribedContents;
    }

    public normalizeLanguage(payload: any) {
        return payload.languages || [];
    }

    public normalizeEducationUse(payload: any) {
        return payload.educational_use || [];
    }

    public normalizeDok(payload: any) {
        return payload.depth_of_knowledge || [];
    }

}

export const facetSerializer = FacetSerializer.instance;