import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'nav-side-menu',
})
export default class NavSideMenuComponent extends Vue {

    // ------------------------------------------------------
    // Properties

    @Prop()
    public facetSetup!: FacetInitializer;

    @Prop()
    public searchEngine!: SearchEngine;

    public get categoryList() {
        return this.facetSetup.getCategories;
    }

    public get requestBody() {
        return this.searchEngine.getRequestBody;
    }

    // ------------------------------------------------------
    // Hooks

    // ------------------------------------------------------
    // Actions

    public onSelectCategory(item: any) {
        const requestBody = this.requestBody;
        this.$set(requestBody, 'category', item.id);
        this.facetSetup.activeCategory = item;
        this.facetSetup.fetchSubjectsByCategory(item);
        this.searchEngine.resetData();
        this.searchEngine.searchContents();
    }

    public onSelectSubject(subjectItem: any) {
        const requestBody = this.requestBody;
        this.$set(requestBody, 'subject', subjectItem.id)
        const { getDropdownFilter } = this.facetSetup;
        getDropdownFilter.framework.items = subjectItem.frameworks;
        this.searchEngine.resetData();
        this.searchEngine.searchContents();
        this.$emit('onClose');
    }

    // ------------------------------------------------------
    // Methods



}