import FacetInitializer from "./facet-initializer";
import SearchEngine from "./search-engine";

export default class FilterManager {

    // --------------------------------------------------------------
    // Properties

    constructor(private searchEngine: SearchEngine, private facetSetup: FacetInitializer) {}


    private defaultFacets: any = ['content-type', 'content-source'];

    // --------------------------------------------------------------
    // Actions


    // --------------------------------------------------------------
    // Methods

    public parseAppliedFilterList() {
        const facetList = this.facetSetup.facetsList;
        const requestBody = this.searchEngine.requestBody;
        const facetKeysList = Object.keys(facetList);
        let appliedFilters: any = [];
        if(facetKeysList) {
            facetKeysList.forEach((facetKeyName: any) => {
               const facetItem =  facetList[facetKeyName];
               if(facetItem && !this.defaultFacets.includes(facetKeyName)) {
                    const { items, filterKey } = facetItem;
                    const filterValue = requestBody[filterKey.facetKey];
                    const activeFilters = items.filter((item: any) => {
                        return filterValue.indexOf(item[filterKey.valueKey]) !== -1;
                    });
                    const parseFilter = [...activeFilters].map(filterItem => {
                        return this.createFilterObj(filterKey.facetKey,filterItem[filterKey.valueKey], filterItem[filterKey.labelKey])
                    })
                    if(activeFilters) {
                        appliedFilters = [...appliedFilters, ...parseFilter];
                    }
               }
            });
        }
        return [...this.appliedSystemFilters(),...appliedFilters];
    }

    public appliedSystemFilters() {
        const systemFacets = this.facetSetup.systemFacetItem;
        const requestBody = this.searchEngine.requestBody;
        const dropdownFilters = this.facetSetup.getDropdownFilter;
        let appliedFilter: any = [];
        if(systemFacets) {
            const {items, filterKey} = systemFacets;
            items.forEach((item: any) => {
               const activeFilter = dropdownFilters[item[filterKey.valueKey]];
               if(activeFilter) {
                    const { items, key } = activeFilter;
                    if(requestBody[key]) {
                        const activeFilterItems = items.filter((item: any) => requestBody[key].indexOf(item[filterKey.valueKey]) !== -1);
                        let parseFilterList = [...activeFilterItems];
                        parseFilterList = parseFilterList.map(filterItem => {
                            return this.createFilterObj(key,filterItem[filterKey.valueKey], filterItem[filterKey.labelKey])
                        });
                        appliedFilter = [...appliedFilter, ...parseFilterList];
                    }
               }
            });
        }
        return [...this.appliedCategories(),...appliedFilter];
    }

    public appliedCategories() {
        const requestBody = this.searchEngine.requestBody;
        const activeCategory = this.facetSetup.activeCategory;
        const defaultFilters = [];
        if(activeCategory){
            const { subjects } = activeCategory;
            if(requestBody.subject && subjects) {
                const activeSubject = subjects.find((subject: any) => subject.id === requestBody.subject);
                const categoryFilter = this.createFilterObj('category',activeCategory.id, activeCategory.title);
                defaultFilters.push(categoryFilter);
                if(activeSubject) {
                    const subjectFilter = this.createFilterObj('subject',activeSubject.id, activeSubject.title);
                    defaultFilters.push(subjectFilter);
                }
            }
        }
        return defaultFilters;
    }

    private createFilterObj(facetKey: any, id: string, label: string) {
        return {
            id,
            label,
            facetKey
        }
    }

    public clearActiveFilter(filter: any) {
        const searchEngine = this.searchEngine;
        const requestBody = searchEngine.requestBody;
        const facetItem = requestBody[filter.facetKey];
        if(facetItem){
            if(Array.isArray(facetItem)) {
                const activeIndex = facetItem.indexOf(filter.id);
                facetItem.splice(activeIndex, 1);
            }else{
                requestBody[filter.facetKey] = null;
                if(filter.facetKey === 'category'){
                    this.facetSetup.activeCategory = null;
                }
            }
            this.clearSystemFilter(filter, requestBody);
        }
        searchEngine.requestBody = requestBody;
        searchEngine.resetData();
        searchEngine.searchContents();
    }

    private clearSystemFilter(filter: any, requestBody: any) {
        const dropdownFilters = this.facetSetup.getDropdownFilter;
        const dropdownList = Object.values(dropdownFilters);
        const keyList = ['category','subject',...dropdownList.map((item: any) => item.key)];
        const activeIndex = keyList.indexOf(filter.facetKey);
        const removedList = keyList.splice(activeIndex, keyList.length);
        removedList.forEach((item: any) => {
            const activeItem = dropdownFilters[item];
            requestBody[item] = null;
            if(activeItem){
                activeItem.items = [];
            }
        });
    }


}