import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FacetActiveFilter from '../facet-active-filter/facet-active-filter.vue';

@Component({
    name: 'facets-search-filters-header',
    components: {
        'facet-active-filter': FacetActiveFilter
    }
})

export default class FacetsSearchFilterHeaderComponent extends Vue {

    // ------------------------------------------------------------
    // Properties

    @Prop()
    public facetSetup!: FacetInitializer;

    @Prop()
    public searchEngine!: SearchEngine;

    public get getCategories() {
        return this.facetSetup.getCategories;
    }

    public get requestBody() {
        return this.searchEngine.getRequestBody;
    }

    public get getDropdownFilter() {
        return this.facetSetup.getDropdownFilter;
    }

    // ------------------------------------------------------------
    // Hooks

    // ------------------------------------------------------------
    // Actions

    public onSelectItem(option: any, item: any) {
        this.$set(this.requestBody, option.key,item.id);
        if(option.eventAction) {
            option.eventAction(this.requestBody);
        }
        this.searchEngine.resetData();
        this.searchEngine.searchContents();
    }

    // ------------------------------------------------------------
    // Methods


}