import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import './plugins/bootstrap-vue';
import { globalConfig } from './global-config';
import { Events } from './setup/events';

Vue.prototype.$globalConfig = globalConfig;

const items: Map<string, any> = new Map<string, any>();
let vue: Vue;

/**
 * @function setOption help to initiate the library data 
 */
export function setOption(name: string, value: string) {
    if(items) {
        items.set(name, value);
    }
}

/**
 * @function mounted help to initiate the library from external application 
 */
export function mounted(mountedAt: string): void {
    vue = new Vue({
        router,
        render: h => h(App),
        data: {
            items
        }
    });
    vue.$mount(mountedAt);
}

/**
 * @function on helps to interact with applications
 */
export function eventData() {
    return vue;
}

/**
 * @function on helps to emit events with applications
 */
 export function customEvent() {
    return Events;
}

/**
 * @function unmonuted destroy the library from the external application
 */
export function unmonuted() {
    vue.$destroy();
}