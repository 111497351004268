import { render, staticRenderFns } from "./facet-form-loader.vue?vue&type=template&id=c79a4544&scoped=true&"
import script from "./facet-form-loader.ts?vue&type=script&lang=ts&"
export * from "./facet-form-loader.ts?vue&type=script&lang=ts&"
import style0 from "./facet-form-loader.scss?vue&type=style&index=0&id=c79a4544&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c79a4544",
  null
  
)

export default component.exports