import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FacetCheckbox from '../facet-checkbox/facet-checkbox.vue';

@Component({
    name: 'facet-form-loader',
    components: {
        'facet-checkbox': FacetCheckbox
    }
})
export default class FacetFormLoader extends Vue {

    // -------------------------------------------------------
    // Properties

    @Prop()
    public filterItem: any;

    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    public get filterKey() {
        return this.filterItem.filterKey;
    }

    public get items() {
        const filterItem = this.filterItem;
        const items = filterItem.items;
        return items.slice(0, this.pageLimit);
    }

    public get requestBody() {
        return this.searchEngine.getRequestBody;
    }

    public pageLimit = 10;

    public get itemCount() {
        return this.filterItem.items.length;
    }

    public get hasShowMore() {
        return this.pageLimit < this.itemCount;
    }

    // -------------------------------------------------------
    // Hooks


    // -------------------------------------------------------
    // Actions

    public onShowMore() {
        this.pageLimit += this.pageLimit;
    }

    public onSubMenuInput(mainMenu: string ,subMenu: any) {
        const filterKey = `${mainMenu}-sub`;
        this.requestBody[filterKey]=subMenu;
        this.searchEngine.resetData();
        this.searchEngine.searchContents();
    }

    // -------------------------------------------------------
    // Methods





}