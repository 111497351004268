import { COLLECTION_TYPE_CONSTANT, CONTENT_SOURCE_API_LIST, CONTENT_SOURCE_LIST, CONTENT_TYPE_LIST } from "@/constants/constants";
import { RequestPayloadModel } from "@/models/request-payload";
import { searchAPI } from "@/providers/apis/search";
import FilterManager from "./filter-manager";
import FacetInitializer from "./facet-initializer";
import LTIInitializer from "./lti-initializer";
import PayloadGenerator from "./payload-generator";
import { Events } from "./events";

export default class SearchEngine {

    public facetSetup!: FacetInitializer;

    /**
     *  @property { Object } facetList It has faceted search filter keys and values mapping fields 
     */
    public facetsList: any = {};

    /**
     * @property { Object } requestBody has the filter content payload data 
     */
    public requestBody: {[key: string]: any} = {
        'content-type': 'course',
        start: 1,
        limit: 10,
        'content-source': 'cap-content',
        terms: null
    };

    /**
     * @property { Object } formatTypes help to identify the filter key based on format
     */
    private formatTypes: {[key: string]: string} = COLLECTION_TYPE_CONSTANT;

    /**
     * @property { Array } contentSource has the list of content source  
     */
    public contentSource: Array<{name: string, key: string}> = CONTENT_SOURCE_LIST;

    public set setRequestBody(requestPayload: {[key: string]: string}) {
        this.requestBody = requestPayload;
    }

    public get getRequestBody() {
        return this.requestBody;
    }

    public contentResults: any = [];

    public totalHitCount = 10;

    public contentTypes = CONTENT_TYPE_LIST;

    public isLoading = false;

    public capPayloadGenerator = new PayloadGenerator(this.requestBody['content-type'], this.requestBody.terms);

    public deepLinkResources: any = [];

    public activeFacetTags: any = [];

    public get getActiveFacetTags() {
        return this.activeFacetTags;
    }

    /**
     * @function buildRequestBody help to build the request payload
     */
    private buildRequestBody() {
        const requestBody = this.requestBody;
        const payload: RequestPayloadModel = {
            q: requestBody.terms || '*',
            start: requestBody.start,
            length: requestBody.limit,
            'flt.publishStatus': 'published,unpublished',
            scopeKey: requestBody['content-source'],
            'flt.subjectClassification': requestBody.category,
            'flt.subject': requestBody.subject,
            'flt.domain': requestBody.domain,
            'flt.course': requestBody.level,
            'flt.fwCode': requestBody.framework,
            'flt.standard': requestBody.competency
        }
        this.buildSearchPayload(payload);
        if(this.formatTypes[requestBody['content-type']]) {
            Object.assign(payload, this.buildTypeFilter(requestBody['content-type']));
        }
        return payload;
    }
    
    /**
     * @function buildTypeFilter help to update the content type filter dynamically
     */
    private buildTypeFilter(format: string) {
        const filterKey = this.formatTypes[format];
        // ----------- Start: Help to identify the sub format filter  --------------
        const subFormatKey = this.formatTypes[`${format}-sub`];
        const requestBody = this.requestBody;
        const subFormatList = requestBody[`${format}-sub`];
        const typeFilters = {
            [filterKey]: format 
        };
        if(subFormatList && subFormatList.length) {
            typeFilters[subFormatKey] = subFormatList.join(',') 
        }
        // ----------- End: Help to identify the sub format filter  --------------

        return typeFilters;
    }

    /**
     * @function searchContents help to search contents based on content types
     */
    public searchContents() {
        this.isLoading = true;
        Events.$emit('onRequestPayload',this.requestBody);
        console.log(this.requestBody, "requestBody---");
        this.buildActiveFacetItems();
        const requestParams = this.requestBody;
        const format = requestParams['content-type'];
        const apiMethod = this.generateAPIMethod(format);
        apiMethod.then((content: any) => {
            this.contentResults = [...this.contentResults,...content.searchResults];
            this.totalHitCount = content.totalHitCount;
            this.isLoading = false;
        });
    }

    /**
     * @function generateAPIMethod help to identify the source method 
     */
    private generateAPIMethod(format: string) {
        const requestBody = this.getRequestBody;
        const contentSourceapis: any = CONTENT_SOURCE_API_LIST;
        let requestPayload: any = this.buildRequestBody();
        const contentSourceMethods = contentSourceapis[requestBody['content-source']];
        const activeMethod = contentSourceMethods[format] || contentSourceMethods;
        if(activeMethod.hasPayloadGenerator) {
            requestPayload = this.capPayloadGenerator.buildCapRequestBody(requestBody, this.facetsList);
        }
        const { apiMethod } = activeMethod ;
        return (searchAPI as any)[apiMethod](requestPayload)
    }

    // --------------------------------------------------------------------
    // Actions

    /**
     * @func facetSearchEvent Help to trigger the search call every time change the filter 
     */
    public facetSearchEvent() {
        this.resetData();
        this.searchContents();
    }

    /**
     * @func onSubmitLTI Help to submit the LTI application data from schoology 
     */
    public onSubmitLTI() {
        const initializer = new LTIInitializer();
        const contentResults = this.contentResults;
        const deeplinkContent = this.deepLinkResources;
        const filteredItem = contentResults.filter((item: any) => deeplinkContent.includes(item.id) );
        initializer.deeplinkContent = filteredItem;
        return initializer.postLTIContent();
    }

    // --------------------------------------------------------------------
    // Methods

    /**
     * @method buildSearchPayload Help to build the dyanmic search payload 
     */
    private buildSearchPayload(payload: any) {
        const facetsList = this.facetsList;
        const requestBody = this.requestBody;
        const facetsKeys = Object.keys(facetsList);
        facetsKeys.forEach(facetKeyName => {
            const activeFacets = facetsList[facetKeyName];
            if(activeFacets) {
                const { filterKey } = activeFacets;
                const activeFilters = requestBody[filterKey.facetKey];
                if(filterKey.search !== 'flt.type' && activeFilters &&
                     ((Array.isArray(activeFilters) && activeFilters.length) || !Array.isArray(activeFilters))) {
                    payload[activeFacets.filterKey.search] = filterKey.hasMultiSelect ? activeFilters.join(',') : activeFilters;
                }
            }
        });
    }

    /**
     * @func clearRequestPayload Help to create applied filter from the search API 
     */
    public clearRequestPayload(filter: any) {
        new FilterManager(this,this.facetSetup).clearActiveFilter(filter);
    }

    public buildActiveFacetItems() {
        const appliedFilter = new FilterManager(this, this.facetSetup);
        this.activeFacetTags = appliedFilter.parseAppliedFilterList();
    }
    
    public resetData() {
        this.requestBody.start = 1;
        this.contentResults = [];
    }

}