import FacetInitializer from '@/setup/facet-initializer';
import SearchEngine from '@/setup/search-engine';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FacetSearchCardComponent from '../facet-search-card/facet-search-card.vue';

@Component({
    name: 'facet-content-loader',
    components: {
        'content-card': FacetSearchCardComponent
    }
})
export default class FacetContentLoaderComponent extends Vue {

    // -----------------------------------------------------
    // Properties

    @Prop()
    public contentResults: any;

    @Prop()
    public searchEngine!: SearchEngine;

    @Prop()
    public facetSetup!: FacetInitializer;

    public get requestBody() {
        return this.searchEngine.requestBody;
    }

    // -----------------------------------------------------
    // Hooks

    // -----------------------------------------------------
    // Actions

    public onLoadMore(event: any) {
        const { scrollTop, offsetHeight, scrollHeight } = event.target;
        if((scrollTop + offsetHeight) >= scrollHeight) {
            this.requestBody.start += 1;
            this.searchEngine.searchContents();
        }
    }


}